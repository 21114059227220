document.addEventListener('DOMContentLoaded', function () {
    iniciarApp();
    eventListerner();
    Modal1();
    Modal2();
    Modal3();

    // closeModal1();

});

function iniciarApp() {
    scrollNav();

}

function scrollNav() {
    const enlaces = document.querySelectorAll('.scroll');

    enlaces.forEach(enlace => {
        enlace.addEventListener('click', function (e) {
            e.preventDefault();

            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({ behavior: "smooth" });  // efecto scroll al llevar al hipervinculo

        });

    });
}



function eventListerner() {
    const mobileMenu = document.querySelector('.mobile-menu');

    mobileMenu.addEventListener('click', navegacionResponsive);
}



function navegacionResponsive() {
    const navegacion = document.querySelector('.nav-tex');
    navegacion.classList.toggle('mostrar');
}


// #########################################
// #########################################


function Modal1() {
    const modalActivo1 = document.querySelector('.Modal_img1');
    const closeModal1 = document.querySelector('.close1')

    modalActivo1.addEventListener('click', openModal1);
    closeModal1.addEventListener('click', cerrarModal1);
}

function openModal1(){
    const open = document.querySelector('.modalContainer1');
    open.classList.add('mostrar1');
}

function cerrarModal1(){
    const close = document.querySelector('.modalContainer1');
    close.classList.remove('mostrar1');
}


// #########################################
// #########################################



function Modal2() {
    const modalActivo2 = document.querySelector('.Modal_img2');
    const closeModal2 = document.querySelector('.close2')

    modalActivo2.addEventListener('click', openModal2);
    closeModal2.addEventListener('click', cerrarModal2);
}

function openModal2(){
    const open = document.querySelector('.modalContainer2');
    open.classList.add('mostrar1');
}

function cerrarModal2(){
    const close = document.querySelector('.modalContainer2');
    close.classList.remove('mostrar1');
}


// #########################################
// #########################################



function Modal3() {
    const modalActivo3 = document.querySelector('.Modal_img3');
    const closeModal3 = document.querySelector('.close3')

    modalActivo3.addEventListener('click', openModal3);
    closeModal3.addEventListener('click', cerrarModal3);
}

function openModal3(){
    const open = document.querySelector('.modalContainer3');
    open.classList.add('mostrar1');
}

function cerrarModal3(){
    const close = document.querySelector('.modalContainer3');
    close.classList.remove('mostrar1');
}


// #########################################
// #########################################



